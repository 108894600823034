import { HomeIcon, WorkerIcon, MobileIcon } from "../components/Icons";

export const processes = [
  {
    id: "1",
    image: <MobileIcon />,
    title: "Pedir um orçamento",
    description:
      "Solicite agora seu orçamento, excelentes preços e opções à pronta entrega.",
    alt: "Imagem de piso vinílico",
  },
  {
    id: "2",
    image: <WorkerIcon />,
    title: "Receber nosso time",
    description:
      "Agende sua visita hoje mesmo, que um de nossos profissionais irá até você.",
    alt: "Imagem de piso laminado",
  },
  {
    id: "3",
    image: <HomeIcon />,
    title: "Sua casa como nova",
    description:
      "Facilitamos o pagamento para que você realize seu sonho de “casa nova”. Conte com a gente.",
    alt: "Imagem de carpete",
  },
];
