export const ArrowRightIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 24 24"
  >
    <path fill="currentColor" d="M16.01 11H4v2h12.01v3L20 12l-3.99-4z" />
  </svg>
);
