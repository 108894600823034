export const MobileIcon = () => (
  <svg
    width="292"
    height="337"
    viewBox="0 0 292 337"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M215.806 261.756C215.806 251.476 215.806 241.776 215.806 232.086C215.806 229.586 215.636 227.066 215.916 224.596C216.446 219.836 218.766 216.486 224.016 216.596C229.166 216.706 231.826 219.866 231.836 224.786C231.906 254.776 232.196 284.776 231.636 314.756C231.396 327.416 220.216 336.456 206.536 336.526C181.796 336.646 157.046 336.566 132.306 336.566C97.3163 336.566 62.3263 336.586 27.3263 336.556C9.89631 336.536 0.0862947 327.156 0.0662947 309.876C-0.0237053 215.396 -0.0236956 120.916 0.0763044 26.4463C0.0963044 9.60629 9.8463 0.096302 26.8063 0.066302C86.2963 -0.013698 145.776 -0.0337035 205.266 0.0762965C225.246 0.106297 238.356 18.9363 231.296 37.6363C230.396 40.0163 226.616 42.6263 224.106 42.6863C221.636 42.7463 218.356 40.1963 216.806 37.8763C215.466 35.8763 215.866 32.5963 215.836 29.8763C215.676 17.1563 214.426 15.8863 201.446 15.8863C184.196 15.8763 166.956 15.8863 149.706 15.8863C109.716 15.8863 69.7263 15.8763 29.7363 15.8863C18.0463 15.8863 16.0263 17.8363 16.0263 29.1363C16.0163 104.116 16.0163 179.106 16.0163 254.086C16.0163 256.516 16.0163 258.936 16.0163 261.776C82.7663 261.756 148.666 261.756 215.806 261.756ZM16.0163 278.526C16.0163 288.816 15.9963 298.286 16.0263 307.756C16.0563 318.476 17.9263 320.366 28.6663 320.366C86.9063 320.376 145.136 320.376 203.376 320.366C205.126 320.366 207.306 320.956 208.536 320.136C211.156 318.376 215.146 315.996 215.286 313.666C215.976 302.076 215.606 290.416 215.606 278.526C148.806 278.526 82.9163 278.526 16.0163 278.526Z"
      fill="#0C3A25"
    />
    <path
      d="M146.036 201.026C136.826 201.026 128.416 201.096 119.996 201.016C103.866 200.856 96.8462 193.716 96.8362 177.476C96.8062 145.736 96.8062 113.996 96.8362 82.2463C96.8562 66.6663 104.206 59.2163 119.686 59.1963C169.176 59.1563 218.656 59.1563 268.146 59.1963C283.736 59.2063 291.206 66.4963 291.266 82.1163C291.396 114.106 291.386 146.096 291.266 178.096C291.206 193.716 283.856 200.916 268.136 201.006C248.146 201.116 228.146 201.236 208.156 200.916C201.986 200.816 197.396 202.306 193.016 206.946C183.066 217.476 172.696 227.646 162.016 237.426C159.296 239.916 154.036 242.316 151.246 241.276C148.616 240.286 146.516 234.796 146.256 231.146C145.586 221.486 146.036 211.766 146.036 201.026ZM161.796 213.286C162.606 213.686 163.416 214.086 164.236 214.496C171.966 206.386 179.366 197.906 187.606 190.346C190.726 187.486 195.756 185.376 199.966 185.286C222.436 184.796 244.936 184.926 267.416 185.146C273.066 185.206 275.466 183.676 275.426 177.556C275.196 145.826 275.246 114.096 275.396 82.3763C275.416 77.3263 273.906 75.2163 268.576 75.2263C218.856 75.3563 169.146 75.3463 119.426 75.2363C114.606 75.2263 112.806 76.9063 112.826 81.7663C112.956 113.996 112.966 146.226 112.816 178.446C112.786 183.896 115.196 185.256 120.126 185.126C129.866 184.876 139.606 185.036 149.356 185.056C160.136 185.086 161.756 186.746 161.796 197.796C161.816 202.966 161.796 208.126 161.796 213.286Z"
      fill="#0C3A25"
    />
    <path
      d="M130.266 298.816C129.786 304.746 127.036 308.186 121.336 308.266C115.626 308.346 112.326 304.886 112.256 299.466C112.176 294.026 115.526 290.456 121.106 290.366C126.706 290.266 129.556 293.776 130.266 298.816Z"
      fill="#0C3A25"
    />
    <path
      d="M193.616 114.566C176.406 114.566 159.206 114.496 141.996 114.606C136.216 114.646 131.276 113.596 130.506 106.816C129.896 101.476 134.246 98.3962 142.046 98.3862C176.956 98.3562 211.866 98.4063 246.776 98.3363C252.546 98.3263 257.496 99.3762 258.276 106.146C258.886 111.486 254.536 114.536 246.726 114.556C229.026 114.596 211.316 114.566 193.616 114.566Z"
      fill="#0C3A25"
    />
    <path
      d="M170.066 154.516C160.326 154.516 150.586 154.516 140.846 154.516C135.216 154.516 130.456 152.696 130.586 146.326C130.716 140.006 135.366 138.476 141.096 138.516C160.576 138.666 180.056 138.656 199.536 138.516C205.216 138.476 210.006 139.866 210.156 146.206C210.316 153.096 205.116 154.556 199.286 154.536C189.546 154.496 179.806 154.516 170.066 154.516Z"
      fill="#0C3A25"
    />
  </svg>
);
