import imagem_piso_vinilico from "../assets/imagem-piso-vinilico.png";
import imagem_piso_laminado from "../assets/imagem-piso-laminado.png";
import imagem_carpete from "../assets/imagem-carpete.png";
import imagem_rodape from "../assets/imagem-rodape.png";
import imagem_porta from "../assets/imagem-porta.png";
import imagem_decoracao from "../assets/imagem-decoracao.png";

export const products = [
  {
    id: "1",
    image: imagem_piso_vinilico,
    title: "Pisos vinílicos",
    description: "Piso flexível com alta durabilidade",
    alt: "Imagem de piso vinílico",
  },
  {
    id: "2",
    image: imagem_piso_laminado,
    title: "Pisos laminados",
    description: "Revestimento durável e fácil de instalar",
    alt: "Imagem de piso laminado",
  },
  {
    id: "3",
    image: imagem_carpete,
    title: "Carpetes",
    description: "Macio e ideal para conforto térmico",
    alt: "Imagem de carpete",
  },
  {
    id: "4",
    image: imagem_rodape,
    title: "Rodapés",
    description: "Acabamento que protege e decora",
    alt: "Imagem de rodapé",
  },
  {
    id: "5",
    image: imagem_porta,
    title: "Portas",
    description: "Funcionais, com estilo e segurança",
    alt: "Imagem de porta",
  },
  {
    id: "6",
    image: imagem_decoracao,
    title: "Decorações",
    description: "Detalhes que valorizam o ambiente",
    alt: "Imagem de itens de decorações",
  },
];
