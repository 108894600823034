import { Element } from "react-scroll";

import Home from "./pages/home";
import Products from "./pages/products";
import AboutUs from "./pages/about-us";
import Brands from "./pages/brands";
import OurProcesses from "./pages/our-process";
import Contact from "./pages/contact";

import WhatsAppButton from "./components/WhatsappButton";

import "./style.scss";

function App() {
  return (
    <main className="main-container">
      <Element name="home">
        <Home />
      </Element>
      <Element name="produtos">
        <Products />
      </Element>
      <Element name="sobre-nos">
        <AboutUs />
      </Element>
      <Brands />
      <Element name="como-trabalhamos">
        <OurProcesses />
      </Element>
      <Element name="contato">
        <Contact />
      </Element>
      <WhatsAppButton />
    </main>
  );
}

export default App;
