import { PrimaryButton } from "../../components/Button";

import { ArrowRightIcon } from "../../components/Icons";

import "./style.scss";

function AboutUs() {
  return (
    <section className="about-container">
      <div className="content">
        <div className="left-content" />
        <div className="right-content">
          <div className="text-container">
            <h2>Sobre nós</h2>
            <h3>As maiores marcas em pisos com os menores preços.</h3>
          </div>
          <p>
            A Norte SC Revestimentos e em parceria com a Pisotiba, seu
            representante comercial em Santa Catarina, vem expandindo sua região
            de atuação, a fim de proporcionar uma experiência única quando se
            trata de tornar seu ambiente residencial ou comercial mais bonito,
            moderno e aconchegante. Somos especializados na venda e instalação
            de pisos laminados, pisos vinílicos, carpetes e rodapés. São mais de
            15 anos de experiência em produtos de alto padrão e durabilidade.
            Situada em Curitiba, traz aos seus clientes a tranquilidade de
            contratar serviços e produtos de qualidade, com profissionais
            qualificados para instalação e assistência técnica, trabalhando com
            as melhores marcas do mercado.
          </p>
          <PrimaryButton size="large" icon={ArrowRightIcon}>
            Realizar orçamento
          </PrimaryButton>
        </div>
      </div>
    </section>
  );
}

export default AboutUs;
