import logo_durafloor from "../assets/logo-durafloor.png";
import logo_eucatex from "../assets/logo-eucatex.png";
import logo_quickstep from "../assets/logo-quickstep.png";
import logo_belgotex from "../assets/logo-belgotex.png";
import logo_santaluiza from "../assets/logo-santaluiza.png";
import logo_ruffino from "../assets/logo-ruffino.png";
import logo_tarkett from "../assets/logo-tarkett.png";

export const brands = [
  {
    id: "1",
    image: logo_durafloor,
    alt: "Logotipo da Durafloor, fabricante de pisos e revestimentos",
  },
  {
    id: "2",
    image: logo_belgotex,
    alt: "Logotipo da Belgotex, fabricante de carpetes e pisos",
  },
  {
    id: "3",
    image: logo_quickstep,
    alt: "Logotipo da Quick-Step, fabricante de pisos laminados e vinílicos",
  },
  {
    id: "4",
    image: logo_eucatex,
    alt: "Logotipo da Eucatex, fabricante de pisos, tintas e revestimentos",
  },
  {
    id: "5",
    image: logo_santaluiza,
    alt: "Logotipo da Santa Luiza, fabricante de rodapés e revestimentos",
  },
  {
    id: "6",
    image: logo_ruffino,
    alt: "Logotipo da Ruffino Acabamentos, especializada em perfis e acabamentos",
  },
  {
    id: "7",
    image: logo_tarkett,
    alt: "Logotipo da Tarkett, fabricante de pisos vinílicos e soluções de revestimento",
  },
];
